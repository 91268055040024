import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";

export default function Eastman() {
    return (
        <div>
            <Grid container>
                <Grid item sm={0} md={2}>
                </Grid>
                <Grid item sm={12} md={8}>
                    <Typography sx={{fontWeight: 'bold'}} gutterBottom variant="h5" component="div">

                    </Typography>

                    <Typography variant="body1">
                        The 2025 NABTCC is hosted at the beautiful Christ Church Cathedral and the Eastman School of Music. There are numerous performance spaces and classrooms available for use throughout the competition.
                    </Typography>

                    <br/>

                    <Typography sx={{marginTop: 3, marginBottom: 2}} gutterBottom variant="h6" component="div">
                        Christ Church Cathedral
                    </Typography>

                    <img src={'/Images/Eastman/Organ-Header-Christ-Church-small-1.jpg'} width={'90%'} alt={'Christ Church'}/>

                    <Typography sx={{marginTop: 3, marginBottom: 2}} gutterBottom variant="h6" component="div">
                        Eastman School of Music
                    </Typography>

                    <img src={'/Images/Eastman/Eastman-School-of-Music-concert-hall.jpg'} width={'90%'} alt={'Eastman School of Music'}/>

                </Grid>
                <Grid item sm={0} md={2}>
                </Grid>
            </Grid>
        </div>

    )
}