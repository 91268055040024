import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";

export default function CSU() {
    return (
        <div>
            <Grid container>
                <Grid item sm={0} md={2}>
                </Grid>
                <Grid item sm={12} md={8}>
                    <Typography sx={{fontWeight: 'bold'}} gutterBottom variant="h5" component="div">
                        Colorado State University
                    </Typography>

                    <Typography variant="body1">
                        The 2024 NABTCC is hosted on the beautiful campus of Colorado State University. There are numerous performance spaces and classrooms availible for use during the competition.
                    </Typography>

                    <br/>

                    <img src={'/Images/CSU/UCA.jpg'} width={'90%'} alt={'university center for the arts'}/>

                    <Typography sx={{marginTop: 3, marginBottom: 2}} gutterBottom variant="h6" component="div">
                        Griffin Concert Hall
                    </Typography>

                    <img src={'/Images/CSU/GriffinHall.jpg'} width={'90%'} alt={'griffin concert hall'}/>

                    <Typography sx={{marginTop: 3, marginBottom: 2}} gutterBottom variant="h6" component="div">
                        Organ Recital Hall
                    </Typography>

                    <img src={'/Images/CSU/CSU-Organ-Hall.webp'} width={'90%'} alt={'organ hall'}/>

                </Grid>
                <Grid item sm={0} md={2}>
                </Grid>
            </Grid>
        </div>

    )
}