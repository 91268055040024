import {Grid} from "@mui/material";
import {ArtistInfo} from "../Constants/ArtistInfo"
import ArtistCard from "../Components/ArtistCard";

export default function Artists() {
    const artists = ArtistInfo.map(
        (info) =>
            <Grid key={info['name']} item xs={12} sm={6} md={4}>
                <ArtistCard name={info['name']} image={'/Images/Artists/' + info['image']} description={info['description']}/>
            </Grid>
    )

    return (
        <Grid container spacing={4}>
            {artists}
        </Grid>
    )
}