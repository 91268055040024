import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";

export default function ArtistCard(props) {
    return (
        <Card>
            <CardMedia
                component="img"
                image={props.image}
                alt={props.name}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    <Link to={`/Artist/${props.name.replace(/\s/g, "")}`}>
                        {props.name}
                    </Link>
                </Typography>
                <Typography sx={{whiteSpace: 'break-spaces'}} variant="body2" color="text.secondary">
                    {props.description}
                </Typography>
            </CardContent>
        </Card>
    )
}