import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import '../Styles/Home.css'
import {Grid, useMediaQuery} from "@mui/material";
import HomeCardContent from "../Components/HomeCardContent";

export default function Home() {
    if (useMediaQuery('(max-width:1100px)'))
        return (

            <Card className={'mainCard'}>
                <CardMedia
                    component="img"
                    image="Images/Closing Concert (1).jpg"
                    alt="closing concert 2022"
                />
                <CardContent>
                    <HomeCardContent/>
                </CardContent>
            </Card>
        )
    return (
        <>
            <Card className={'mainCard'} sx={{display: 'flex'}} direction="row">
                <Grid container alignItems="flex-start">
                    <Grid item sm={5} md={4} lg={4}>
                        <CardMedia
                            component="img"
                            image="Images/20220411_162743.jpg"
                            alt="closing concert 2022"
                        />
                    </Grid>
                    <Grid item sm={7} md={8} lg={8}>
                        <CardContent>
                            <HomeCardContent/>
                        </CardContent>
                    </Grid>
                </Grid>
            </Card>

        </>
    )
}