import Typography from "@mui/material/Typography";
import {Avatar, Grid} from "@mui/material";
import Box from "@mui/material/Box";

export default function Sponsors() {
    return (
        <Box sx={{mt: 1}}>
            <Typography gutterBottom variant="h6" textAlign={'center'}>
                Sponsors
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={6} sm={3}>
                    <Typography gutterBottom variant="body2" textAlign={'center'}>
                        Historic Brass Society
                    </Typography>
                    <img width='100%' height='auto' alt="Historic Brass Society" src="/Images/Sponsors/HBSLogo.gif" />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography sx={{pb:1}} gutterBottom variant="body2" textAlign={'center'}>
                        BfB Brass™
                    </Typography>
                    <img width='100%' height='auto' alt="BfB Brass" src="/Images/Sponsors/BFB-Brass-left-grey.png" />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography gutterBottom variant="body2" textAlign={'center'}>
                        Maller Brass Instruments
                    </Typography>
                    <img width='100%' height='auto' alt="Maller Brass Instruments" src="/Images/Sponsors/MALLER BRASS LOGO.png" />
                </Grid>
                <Grid sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column'}} item xs={6} sm={3}>
                    <Typography gutterBottom variant="body2" textAlign={'center'}>
                        CSU ITG
                    </Typography>
                    <Avatar sx={{width: '70%', height: 'auto'}} alt="CSU ITG" src="/Images/Sponsors/ITGLogo.png" />
                </Grid>
            </Grid>
        </Box>
    )
}