import React from "react";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";

export default function FormLinks() {
    return (
        <>
            <Link to={'https://forms.gle/umNWyN2LYnfJRcJH9'} target="_blank" rel="noopener noreferrer"><Button color={'secondary'} variant="contained" sx={{mt: 1, mr: 1}}>Solo Registration</Button></Link>
            <Link to={'https://forms.gle/3Nzkt2rfSGRpFKnM9'} target="_blank" rel="noopener noreferrer"><Button color={'secondary'} sx={{mr: 1, mt: 1}} variant="contained">Ensemble Registration</Button></Link>
            {/*<Link to={'https://docs.google.com/forms/d/e/1FAIpQLSc8zkfwItONoIJwXIaupx6hHLvwbDt1tPStbMty57W02-ru1g/viewform?usp=sf_link'} target="_blank" rel="noopener noreferrer"><Button color={'secondary'} sx={{mt: 1}} variant="contained">Presentation Application</Button></Link>*/}
            {/*<Link to={'/Schedule'}><Button color={'secondary'} sx={{mt: 1}} variant="contained">Schedule of Events</Button></Link>*/}
        </>
    )
}