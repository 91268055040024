import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import '../Styles/Papers.css'

export default function Papers() {
    return (
        <Grid container>
            <Grid item sm={0} md={2}>
            </Grid>
            <Grid item sm={12} md={8}>
                <Typography sx={{fontWeight: 'bold'}} gutterBottom variant="h5" component="div">
                    Paper Presentations
                </Typography>

                <Typography variant="body1">
                    Five paper presentations will be accepted for the 2025 conference. Presenters will present a
                    20-minute presentation with 5 minutes for questions. There are no restrictions on the topics that
                    may be covered, although the Historic Brass Society mission statement notes that "the history,
                    music, literature and performance practice of early brass instruments such as natural trumpet,
                    natural horn, early trombone, cornetto, serpent, keyed bugle, keyed trumpet, early valve horn, 19th
                    century brass instruments are some of the main issues of concern to the HBS." Paper presenters shall
                    receive no remuneration and will be responsible for their own expenses.
                </Typography>

                <br/>

                <Typography variant="body1">
                    Proposals must be submitted by current Historic Brass Society (HBS) members (ensure that membership
                    is for 2025 or extending into 2025) – membership information can be found at <a
                    href={'https://www.historicbrass.org'}>www.historicbrass.org</a>. Proposals submitted by non-HBS
                    members will not be considered by the screening committee.
                </Typography>

                <br/>

                <Typography variant="body1">
                    Proposals should include an abstract of 250 words, a 75-word biography, and email contact
                    information. All proposals should be submitted by email to Victor Yuen at victoryuenmusic@gmail.com
                    no later than January 15, 2025. The screening committee will notify winning presentations by
                    February 1, 2025.
                </Typography>

                <br/>

                <Typography variant="body1">
                    The paper presentations are being organized by Victor Yuen.
                    <br/>
                    <br/>
                    <img className={'organizer-image'} alt={'victor yuen'} src={'/Images/Artists/VictorYuen.jpg'}/>
                </Typography>

            </Grid>
            <Grid item sm={0} md={2}>
            </Grid>
        </Grid>
    )
}