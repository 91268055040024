import {ArtistInfo} from "../Constants/ArtistInfo";
import {useLoaderData} from "react-router-dom";
import {Grid} from "@mui/material";
import { Image } from 'mui-image'
import Typography from "@mui/material/Typography";

export function loader({ params }) {
    for (let i = 0; i < ArtistInfo.length; i++)
        if (ArtistInfo[i]['name'].replace(/\s/g, "") === params.ArtistName)
            return ArtistInfo[i]
}

export default function Artist() {
    const artist = useLoaderData()
    console.log(artist)

    return (
        <Grid alignItems="flex-start" container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
                <Image duration={500} sx={{height: '100%', width: 'auto'}} src={'/Images/Artists/' + artist['image']}/>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <Typography gutterBottom variant="h5" component="div">
                    {artist['name']}
                </Typography>
                <Typography sx={{whiteSpace: 'break-spaces'}} variant="body1" color="text.secondary">
                    {artist['bio']}
                </Typography>
            </Grid>
        </Grid>
    )
}