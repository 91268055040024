import ResponsiveAppBar from "./Components/ResponsiveAppBar";
import {Outlet} from "react-router-dom";
import Container from "@mui/material/Container";
import {Paper} from "@mui/material";

export default function Root() {
    return (
        <div>
            <ResponsiveAppBar backgroundColor={'#363537'}/>


            <Container maxWidth="lg" disableGutters={true}>
                <Paper sx={{padding: '4.5vh 0 4.5vh 0'}}>
                    <Container sx={{padding: '0 40px 0 40px'}} className={'innerContainer'} maxWidth="lg" disableGutters={false}>
                        <Outlet/>
                    </Container>
                </Paper>
            </Container>
        </div>
    )
}