import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import Sponsors from "./Sponsors";
import React from "react";
import {useMediaQuery} from "@mui/material";
import FormLinks from "./FormLinks"

export default function HomeCardContent() {

    let headingSize = 'h5'
    if (useMediaQuery('(max-width:1100px)'))
        headingSize = 'h6'

    return (
        <div>
            <Typography sx={{fontWeight: 'bold'}} gutterBottom variant={headingSize} component="div">
                2025 North American Baroque Trumpet Competition and Conference
            </Typography>
            <Typography variant="h6" color="text.secondary" >
                April 25-27th, 2025<br/>
                <Link to={'/Eastman'}>
                    Eastman School of Music, Rochester, New York
                </Link>

            </Typography>

            <FormLinks/>

            <Typography variant="body1" sx={{marginTop: 1}}>
                The 2025 North American Baroque Trumpet Competition and Conference is a three-day event to be held in Rochester, New York. The event consists of concerts, master classes, paper presentations, and a competition—all related to the baroque trumpet.
            </Typography>

            <Sponsors/>
        </div>
    )
}